import { ReactElement, useState } from 'react';
// import { Button } from '@/components/ui/button';
// import { Checkbox } from '@/components/ui/checkbox';
// import { Input } from '@/components/ui/input';

export const App = (): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className='min-h-screen bg-white'>
      <header className='bg-gray-800 p-4'>
        <img
          alt='Логотип Groover'
          className='text-white'
          height={40}
          src='/placeholder.svg'
          width={100}
        />
      </header>
      <main className='container mx-auto px-4 py-8'>
        <div className='flex flex-col gap-8 md:flex-row'>
          <div className='rounded-lg bg-[#e86452] p-8 text-white md:w-1/2'>
            <h1 className='mb-6 text-3xl font-bold'>
              Присоединяйтесь к нашему списку музыкальных кураторов и
              профессионалов
            </h1>
            <div className='space-y-6'>
              <div>
                <h2 className='text-xl font-semibold'>
                  Будьте первыми, кто откроет новых артистов
                </h2>
                <p className='text-sm'>
                  Присоединяйтесь к команде кураторов и профессионалов Groove,
                  чтобы находить топовые таланты в музыкальной индустрии.
                </p>
              </div>
              <div>
                <h2 className='text-xl font-semibold'>
                  Получайте целевые, централизованные заявки
                </h2>
                <p className='text-sm'>
                  Перестаньте просматривать тонны нерелевантных, спамных
                  запросов. Groover экономит ваше время, централизуя все
                  релевантные заявки в одном удобном месте.
                </p>
              </div>
              <div>
                <h2 className='text-xl font-semibold'>
                  Монетизируйте свою работу
                </h2>
                <p className='text-sm'>
                  Ваше время и ответы ценны! На Groover вы получаете оплату за
                  каждую обратную связь, которую вы даете.
                </p>
              </div>
            </div>
            <a className='mt-4 inline-block text-sm underline' href='#'>
              Узнайте больше о кураторах и профессионалах Groover
            </a>
          </div>
          <div className='md:w-1/2'>
            <h2 className='mb-6 text-2xl font-bold'>
              Создайте аккаунт и откройте для себя новые таланты
            </h2>
            <form className='space-y-4'>
              <input placeholder='Имя' type='text' />
              <input placeholder='Фамилия' type='text' />
              <input placeholder='Адрес электронной почты' type='email' />
              <div className='relative'>
                <input
                  placeholder='Создайте новый пароль'
                  type={showPassword ? 'text' : 'password'}
                />
                <button
                  className='absolute right-2 top-1/2 -translate-y-1/2 transform'
                  onClick={() => setShowPassword(!showPassword)}
                  type='button'
                >
                  {showPassword ? (
                    <div className='text-gray-500 h-5 w-5'>Иконка глаза</div>
                  ) : (
                    <div className='text-gray-500 h-5 w-5'>Иконка глаза</div>
                  )}
                </button>
              </div>
              <div className='flex items-center space-x-2'>
                <input id='terms' type='checkbox' />
                <label className='text-sm' htmlFor='terms'>
                  Я прочитал и принимаю{' '}
                  <a className='text-[#e86452]' href='#'>
                    общие условия обслуживания
                  </a>
                </label>
              </div>
              <button
                className='w-full bg-[#e86452] hover:bg-[#d55a4a]'
                type='button'
              >
                Зарегистрироваться
              </button>
            </form>
            <p className='mt-4 text-center'>
              Уже есть аккаунт Groover?{' '}
              <a className='text-[#e86452]' href='#'>
                Войти
              </a>
            </p>
          </div>
        </div>
      </main>
      <footer className='bg-gray-900 py-8 text-white'>
        <div className='container mx-auto px-4'>
          <div className='grid grid-cols-2 gap-8 md:grid-cols-4'>
            <div>
              <h3 className='mb-4 font-semibold'>
                Кураторы/профессионалы по жанрам
              </h3>
              <ul className='space-y-2 text-sm'>
                <li>Топ Классическая/Инструментальная</li>
                <li>Топ Электронная</li>
                <li>Топ Фолк/Акустическая</li>
                <li>Топ Хип-хоп/Рэп</li>
                <li>Топ Джаз</li>
              </ul>
            </div>
            <div>
              <h3 className='mb-4 font-semibold'>
                Кураторы/профессионалы по поджанрам
              </h3>
              <ul className='space-y-2 text-sm'>
                <li>Топ Альтернативный рок</li>
                <li>Топ Танцевальная музыка</li>
                <li>Топ Танцевальная поп-музыка</li>
                <li>Топ Хип-хоп</li>
                <li>Топ Инди-фолк</li>
              </ul>
            </div>
            <div>
              <h3 className='mb-4 font-semibold'>
                Кураторы/профессионалы по типу
              </h3>
              <ul className='space-y-2 text-sm'>
                <li>Топ музыкальные Букеры</li>
                <li>Топ музыкальные Каналы</li>
                <li>Топ музыкальные Журналисты</li>
                <li>Топ музыкальные Лейблы</li>
                <li>Топ музыкальные Медиа</li>
              </ul>
            </div>
            <div>
              <h3 className='mb-4 font-semibold'>
                Кураторы/профессионалы по странам
              </h3>
              <ul className='space-y-2 text-sm'>
                <li>Топ музыка Бельгия</li>
                <li>Топ музыка Бразилия</li>
                <li>Топ музыка Франция</li>
                <li>Топ музыка Германия</li>
                <li>Топ музыка Италия</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
